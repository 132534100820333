import { Col, Row, Typography, Input, Button, Grid } from 'antd';
import MenuBar from '../components/MenuBar'
import { useState } from "react";
import LinkedIn from '../images/linkedin-icon.svg';
import Facebook from '../images/facebook-icon.svg';
import Twitter from '../images/twitter-icon.svg';
import Instagram from '../images/instagram-icon.svg';

const { Text, Title, Paragraph } = Typography;
const { TextArea } = Input;
const { useBreakpoint } = Grid;

function Contact() {
const screens = useBreakpoint();
  return (
    <>
      <MenuBar />
      <Row>
        <Col xs={{ span: 18, offset: 3 }} sm={{ span: 20, offset: 2 }} lg={{ span: 6, offset: 6 }} style={{ paddingTop: 0, paddingRight: screens?.lg ? 40 : 0 }}>
            <Title style={{ fontFamily: "Roboto, sans-serif", fontSize: 28, fontWeight: 500 }}>Let's work together</Title>
            <Paragraph style={{ fontFamily: "Roboto, sans-serif", fontSize: 16, fontWeight: 400, lineHeight: "23px", letterSpacing: '5%', color: '#444444', marginTop: 20 }}>
                At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident.
            </Paragraph>
            <Title style={{ fontFamily: "Roboto, sans-serif", fontSize: 28, fontWeight: 500, paddingTop: 0 }}>Email</Title>
            <Paragraph style={{ fontFamily: "Roboto, sans-serif", fontSize: 16, fontWeight: 400, lineHeight: "23px", letterSpacing: '5%', color: '#444444', marginTop: 20 }}>
                johnratford@aol.com
            </Paragraph>
            <Title style={{ fontFamily: "Roboto, sans-serif", fontSize: 28, fontWeight: 500, paddingTop: 0 }}>Socials</Title>
            <Row gutter={[16, 0]} style={{ marginTop: 20 }}>
                <Col>
                    <img src={LinkedIn} width={20} style={{ cursor: 'pointer', opacity: 0.8 }} />
                </Col>
                <Col>
                    <img src={Facebook} width={20} style={{ cursor: 'pointer', opacity: 0.8 }} />
                </Col>
                <Col>
                    <img src={Twitter} width={20} style={{ cursor: 'pointer', opacity: 0.8 }} />
                </Col>
                <Col>
                    <img src={Instagram} width={20} style={{ cursor: 'pointer', opacity: 0.8 }} />
                </Col>
            </Row>
        </Col>
        <Col xs={{ span: 18, offset: 3 }} sm={{ span: 20, offset: 2 }} lg={{ span: 6, offset: 0 }} style={{ paddingTop: screens?.lg? 40 : 60, marginBottom: 100 }}>
            <Row>
                <Text style={{ fontFamily: "Roboto, sans-serif", fontSize: 16, color: '#313131', fontWeight: 500 }}>Full Name</Text>
            </Row>
            <Row style={{ marginTop: 10 }}>
                <Input style={{ border: 'none', borderBottom: '1px solid #BABABA', borderRadius: 0, paddingBottom: 4 }} placeholder="Name here" size="small" />
            </Row>
            <Row>
                <Text style={{ fontFamily: "Roboto, sans-serif", fontSize: 16, color: '#313131', fontWeight: 500, marginTop: 40 }}>Email</Text>
            </Row>
            <Row style={{ marginTop: 10 }}>
                <Input style={{ border: 'none', borderBottom: '1px solid #BABABA', borderRadius: 0, paddingBottom: 4 }} placeholder="me@email.com" size="small" />
            </Row>
            <Row>
                <Text style={{ fontFamily: "Roboto, sans-serif", fontSize: 16, color: '#313131', fontWeight: 500, marginTop: 40 }}>Type your message here</Text>
            </Row>
            <Row style={{ marginTop: 10 }}>
                {/* <span class="textarea" role="textbox" contenteditable /> */}
                <TextArea style={{ border: 'none', borderBottom: '1px solid #BABABA', borderRadius: 0, paddingBottom: 4 }} rows={1} placeholder="Write message here" size="small" />
            </Row>
            <Row justify="end" style={{ marginTop: 10 }}>
                <Button style={{ transform: 'translateX(15px)', fontSize: 14, fontWeight: 500, fontFamily: "Roboto, sans-serif", color: '#C6C6C6' }} type="text">Send</Button>
            </Row>
        </Col>
      </Row>
    </>
  );
}

export default Contact;
