import { Col, Row, Typography, Button, Grid } from 'antd';
import MenuBar from '../components/MenuBar'
import { useState } from "react";
import './landing.css';
import House1 from '../images/House1.jpg';
import Image2 from '../images/Image2.jpg';
import Image3 from '../images/Image3.jpg';
import Image4 from '../images/Image4.jpg';
import Image5 from '../images/Image5.jpg';
import Image9 from '../images/Image9.jpg';

const { useBreakpoint } = Grid;

function Landing() {
  const [imageModalActive, setImageModalActive] = useState(false);
  const [activeImage, setActiveImage] = useState(null);
  const screens = useBreakpoint();
  const [images] = useState({
    xs: [
      { id: 1, url: House1, largeSize: true },
      { id: 2, url: Image2, largeSize: false },
      { id: 3, url: Image3, largeSize: false },
      { id: 4, url: Image4, largeSize: false },
      { id: 5, url: Image5, largeSize: true },
      { id: 6, url: Image9, largeSize: false },
      { id: 7, url: Image3, largeSize: false },
      { id: 8, url: Image2, largeSize: false },
      { id: 9, url: House1, largeSize: false },
    ],
    md: [
      { id: 1, url: House1, largeSize: true },
      { id: 2, url: Image2, largeSize: false },
      { id: 4, url: Image5, largeSize: true },
      { id: 3, url: Image3, largeSize: false },
      { id: 5, url: Image4, largeSize: false },
      { id: 6, url: Image9, largeSize: false },
      { id: 7, url: Image4, largeSize: false },
      { id: 8, url: Image9, largeSize: false },
    ],
    lg: [
      { id: 1, url: House1, largeSize: true },
      { id: 2, url: Image2, largeSize: false },
      { id: 3, url: Image3, largeSize: false },
      { id: 4, url: Image5, largeSize: true },
      { id: 5, url: Image4, largeSize: false },
      { id: 6, url: Image9, largeSize: false },
    ],
  });
  const sizeChooser = () => {
    if (screens?.lg) return "lg";
    if (screens?.md) return "md";
    return "xs";
  }
  return (
    <>
      {
        imageModalActive && (
          <div className='modal-container'>
            <div style={{ height: '100vh', width: '100vw', backgroundColor: 'none', position: 'fixed', top: 0, left: 0, zIndex: 50 }} onClick={() => setImageModalActive(false)} />
            <div
              className='modal-card'
              style={{ backgroundImage: `url(${images[sizeChooser()]?.filter((image) => image.id === activeImage)?.[0]?.url}` }}
            />
          </div>
        )
      }
      <MenuBar />
      <Row style={imageModalActive ? { overflow: 'hidden', height: '100vh', paddingTop: '0.5rem' } : { paddingTop: '0.5rem' }}>
        <Col xs={{ span: 18, offset: 3 }} sm={{ span: 20, offset: 2 }} lg={{ span: 12, offset: 6 }}>
          <div className="image-mosaic">
            {images[sizeChooser()]?.map((image) => (
              <div
                key={image?.id}
                className={`card ${image?.largeSize ? "card-large" : ""}`}
                style={{ backgroundImage: `url(${image?.url}` }}
                onClick={() => {setActiveImage(image?.id); setImageModalActive(true);}}
              />
            ))}
          </div>
        </Col>
      </Row>
    </>
  );
}

export default Landing;
