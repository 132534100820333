import { Col, Row, Button, Typography, Grid, Drawer } from 'antd';
import {
  Link
} from "react-router-dom";
import { MenuOutlined } from '@ant-design/icons';
import { useEffect, useState } from "react";

const { Title } = Typography;
const { useBreakpoint } = Grid;

function MenuBar({ hideshadow }) {
  const [atTop, setAtTop] = useState(true);
  const [open, setOpen] = useState(false);
  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };
  const screens = useBreakpoint();
  useEffect(() => {
    if (!hideshadow) {
      const scrollEvent = () => {
        setAtTop(window.scrollY === 0);
      };

      window.addEventListener("scroll", scrollEvent);
      return () => {
        window.removeEventListener("scroll", scrollEvent, { passive: true });
      }
    }
  }, [hideshadow]);

  return (
    <Row style={{ height: screens?.xxl ? 120 : 60, position: 'sticky', top: 0, left: 0, width: '100%', backgroundColor: '#ffffff', zIndex: 10, boxShadow: atTop ? 'rgba(0, 0, 0, 0) 0px 2px 8px' : 'rgba(0, 0, 0, 0.1) 0px 2px 8px', width: '100%', transition: 'box-shadow 0.1s ease-in-out' }}>

      <Col xs={{ span: 18, offset: 3 }} sm={{ span: 20, offset: 2 }} lg={{ span: 12, offset: 6 }}>
        <Row style={{ height: '100%' }} align="middle" >
          <Col span={12}>
            <Link to="/">
              <Title style={{ fontSize: 16, fontFamily: "IBM Plex Sans, sans-serif", fontWeight: 400 }}><span style={{ color: '#FE7347' }}>drawing</span>inspiration</Title>
            </Link>
          </Col>
          <Col style={{ transform: 'translateX(8px)' }} xs={0} md={12}>
            <Row align="middle" justify="end">
              <Link to="/about" style={{ color: '#000000', fontSize: 13, fontFamily: "IBM Plex Sans, sans-serif", fontWeight: 400, padding: '4px 6px', marginLeft: 8 }}>
                ABOUT
              </Link>
              <Link to="/portfolio" style={{ color: '#000000', fontSize: 13, fontFamily: "IBM Plex Sans, sans-serif", fontWeight: 400, padding: '4px 6px', marginLeft: 8 }}>
                PORTFOLIO
              </Link>
              <Link to="/contact" style={{ color: '#000000', fontSize: 13, fontFamily: "IBM Plex Sans, sans-serif", fontWeight: 400, padding: '4px 6px', marginLeft: 8 }}>
                CONTACT
              </Link>
            </Row>
          </Col>
          <Col style={{ transform: 'translateX(8px)' }} xs={12} md={0}>
            <Row align="middle" justify="end">
              <Button icon={<MenuOutlined />} onClick={showDrawer} />
            </Row>
          </Col>
        </Row>
      </Col>
      <Drawer placement="right" onClose={onClose} closable={false} width={"75%"} open={open}>
        <Col>
        <Row>
            <Link to="/" style={{ color: '#313131', fontSize: 24, fontFamily: "IBM Plex Sans, sans-serif", fontWeight: 400, padding: '4px 6px', marginLeft: 8, marginTop: 20 }}>
              HOME
            </Link>
          </Row>
          <Row>
            <Link to="/about" style={{ color: '#313131', fontSize: 24, fontFamily: "IBM Plex Sans, sans-serif", fontWeight: 400, padding: '4px 6px', marginLeft: 8, marginTop: 20 }}>
              ABOUT
            </Link>
          </Row>
          <Row>
            <Link to="/portfolio" style={{ color: '#313131', fontSize: 24, fontFamily: "IBM Plex Sans, sans-serif", fontWeight: 400, padding: '4px 6px', marginLeft: 8, marginTop: 20 }}>
              PORTFOLIO
            </Link>
          </Row>
          <Row>
            <Link to="/contact" style={{ color: '#313131', fontSize: 24, fontFamily: "IBM Plex Sans, sans-serif", fontWeight: 400, padding: '4px 6px', marginLeft: 8, marginTop: 20 }}>
              CONTACT
            </Link>
          </Row>
        </Col>
      </Drawer>
    </Row>
  );
}

export default MenuBar;
