import { Col, Row, Typography, Grid, Select, Form } from 'antd';
import MenuBar from '../components/MenuBar'
import { useEffect, useState } from "react";
import Image10 from '../images/Image10.jpg';
import Image2 from '../images/Image2.jpg';
import IMG_5153 from '../images/IMG_5153.jpg';
import IMG_1154 from '../images/IMG_1154.jpg';

const { Title, Paragraph } = Typography;
const { useBreakpoint } = Grid;

const SectionBody = ({
  id,
  title,
  text,
  image
}) => {
  const screens = useBreakpoint();
  return <Row style={{ marginBottom: 30 }} id={id}>
    <Col xs={{ span: 24 }} lg={{ span: 7 }}>
      <Title style={{ fontSize: 22, fontFamily: "Merriweather, serif", fontWeight: 500, margin: 0 , color: '#313131'}}>{title}</Title>
    </Col>
    <Col xs={{ span: 24 }} lg={{ span: 17 }} style={{ marginTop: !screens?.lg ? 20 : 0 }}>
      <Paragraph style={{ fontSize: 14, fontWeight: 400, lineHeight: "23px", letterSpacing: '5%', color: '#444444' }}>
        {
          text?.map((line, index) => {
            if (index === 0) {
              return <span key={index}>{line}</span>;
            };
            return (
              <span key={index}>
                <br />
                <br />
                {line}
              </span>
            )
          })
        }
      </Paragraph>
      <img src={image} style={{ width: screens?.lg ? '66%' : '100%', maxWidth: '100vh', float: 'right' }} />
    </Col>
  </Row>
}

function About() {
  const sections = [
    {
      id: 'illustration',
      name: "Illustration",
      text: [
        'As an illustrator I work in a multitude of styles and media producing illustrations for new concepts, business-to-business presentations and internal product development.',
        'In a workshop setting, my product, concept and packaging illustrations lend an extra dimension, create focus and can develop extraordinary results in a business ideation process. In particular, I work with innovation teams to provide highly effective images to communicate new product development throughout the process.  My book illustrations have focussed on food, blending illustrations with timelines and infographics.',
        'I have produced pen-and-ink architectural illustrations and technical drawings to support engineering companies.   My drawings are prominent in the local charities and community events that I support.'
      ],
      image: Image10
    },
    {
      id: 'graphic_design',
      name: "Graphic Design",
      text: [
        'I have a background as a creative in print, packaging, advertising, marketing, web and product design, with an eye for detail and a keen sense for matching the functionality of design to the client requirements. I have a proven track record, working alongside clients at all levels within organisations.',
        'I cover all elements of corporate literature, image and branding together with packaging from initial concepts through design, specifications and prototyping. I have designed websites and mobile apps both from initial wireframes to upgrading specific on-screen assets.',
        'I am adept at simplifying complex concepts and data in a more visual way through infographics and creating illustrated timelines and maps. I am proficient with the Adobe Creative Suite: InDesign, Illustrator, Photoshop and Microsoft: Word, Powerpoint and Excel.'
      ],
      image: Image2
    },
    {
      id: 'facilitation',
      name: "Facilitation",
      text: [
        'I work as an instigator for innovation and development within established and emerging companies and help build inspiration using data, observation, immersion and stimuli to produce easily communicable graphics and reports.',
        'My interest and experience in the food industry have resulted in walking tours of food specialisms in London and across Europe. I also guide insight trawls and have collected and created stimuli for innovation workshops.',
        'I have significant reach across the food industry however my broad background gives me a unique insight across many categories and industries.',
      ],
      image: IMG_5153
    },
    {
      id: 'graphic_recording',
      name: "Graphic Recording",
      text: [
        'I produce large scale live illustration as an engaging way to capture the content of meetings, conferences and events. This is a growing area of work with more businesses appreciating the usefulness of a graphic record of an event as well as entertaining and stimulating conversation.',
        'It has been successful as an aide-memoire and in engaging a wider audience post-event.',
      ],
      image: IMG_1154
    }
  ];
  const screens = useBreakpoint();
  const [activeSection, setActiveSection] = useState("illustration");
  const [atTop, setAtTop] = useState(true);
  const [form] = Form.useForm();

  const selectActiveSection = (section_id) => {
    const section = document.getElementById(section_id);
    console.log('scroll to', section_id);
    const y = section.getBoundingClientRect().top + window.scrollY - (screens?.lg ? 220 : 150);
    window.scrollTo({
      behavior: "smooth",
      top: y
    });
    setActiveSection(section_id)
  }

  useEffect(() => {
    const scrollEvent = () => {
      setAtTop(window.scrollY === 0);
      const currentSection = sections?.map((section) => ({
        id: section?.id,
        start: document.getElementById(section?.id).getBoundingClientRect().top + window.scrollY - (screens?.lg ? 220 : 150)
      }))?.filter((section) => section?.start < (window.scrollY + window.innerHeight / 2));
      const currentSectionID =  currentSection?.[currentSection?.length - 1]?.id;
      setActiveSection(currentSectionID);
      form.setFieldsValue({sectionSelection: currentSectionID})
    };

    window.addEventListener("scroll", scrollEvent);
    return () => {
      window.removeEventListener("scroll", scrollEvent, { passive: true });
    }
  }, [screens]);

  return (
    <>
      <MenuBar hideshadow />
      <Row style={{ borderTop: atTop ? '' : '1px solid #eee', position: 'sticky', width: '100%', top: screens?.xxl ? 120 : 60, left: 0, backgroundColor: '#ffffff', zIndex: 9, padding: screens?.lg ? '20px 0px' : '10px 0px', boxShadow: atTop ? 'rgba(0, 0, 0, 0) 0px 2px 8px' : 'rgba(0, 0, 0, 0.1) 0px 2px 8px', width: '100%', marginBottom: screens?.lg ? 40 : 10, transition: 'box-shadow 0.1s ease-in-out' }}>
        <Col xs={{ span: 0 }} lg={{ span: 12, offset: 6 }}>
          {
            sections?.map((section, index) => {
              if (index === 0) {
                return <span key={`selection_button_${section?.id}`} onClick={() => selectActiveSection(section?.id)} id={`selection_button_${section?.id}`} style={{ fontSize: 12, textDecoration: 'underline', fontFamily: "Merriweather, serif", padding: 0, cursor: 'pointer', color: activeSection === section?.id ? '#313131' : '#888888', fontWeight: 500 }}>{section?.name}</span>
              }
              return <span key={`selection_button_${section?.id}`}>
                <span style={{ fontSize: 12, fontFamily: "Merriweather, serif", margin: '0 8px', color: '#888888' }}>&</span>
                <span onClick={() => selectActiveSection(section?.id)} id={`selection_button_${section?.id}`} style={{ fontSize: 12, textDecoration: 'underline', fontFamily: "Merriweather, serif", padding: 0, cursor: 'pointer', color: activeSection === section?.id ? '#313131' : '#888888' }}>{section?.name}</span>
              </span>
            })
          }
        </Col>
        <Col xs={{ span: 18, offset: 3 }} sm={{ span: 20, offset: 2 }} lg={{ span: 0 }} style={{ height: 30 }}>
          <Form name="sectionSelection_form" form={form}>
            <Form.Item name="sectionSelection">
              <Select
                defaultValue={sections?.[0]?.id}
                style={{
                  transform: 'translate(-11px)',
                  width: 160
                }}
                placement="bottomLeft"
                bordered={false}
                options={
                  sections?.map((section) => ({
                    value: section?.id,
                    label: section?.name
                  }))
                }
                onSelect={((e) => selectActiveSection(e))}
              />
            </Form.Item>
          </Form>
        </Col>
      </Row>
      <Row>
        <Col xs={{ span: 18, offset: 3 }} sm={{ span: 20, offset: 2 }} lg={{ span: 12, offset: 6 }}>
          {
            sections?.map((section) => {
              return <SectionBody id={section?.id} title={section?.name} text={section?.text} image={section?.image} key={section?.name} />
            })
          }
        </Col>
      </Row>
    </>
  );
}

export default About;
