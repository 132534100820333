import { Col, Row, Typography, Checkbox, Input, Button, Grid, Modal, Form } from 'antd';
import { useState } from "react";
import { FilterOutlined } from '@ant-design/icons';
import './portfolio.css';
import MenuBar from '../components/MenuBar';
import House1 from '../images/House1.jpg';
import Image2 from '../images/Image2.jpg';
import Image3 from '../images/Image3.jpg';
import Image4 from '../images/Image4.jpg';
import Image5 from '../images/Image5.jpg';
import Image9 from '../images/Image9.jpg';

const { Text } = Typography;
const { useBreakpoint } = Grid;

function Portfolio() {
  const [form] = Form.useForm();
  const [activeImage, setActiveImage] = useState(null);
  const [imageModalActive, setImageModalActive] = useState(false);
  const [selectedTypes, setSelectedTypes] = useState([]);
  const [selectedKeywords, setSelectedKeywords] = useState([]);
  const screens = useBreakpoint();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };

  const [images] = useState([
    { id: 1, url: House1, types: ['illustration'], keywords: [] },
    { id: 2, url: Image2, types: [], keywords: ['packaging'] },
    { id: 3, url: Image3, types: [], keywords: ['packaging'] },
    { id: 4, url: Image5, types: [], keywords: [] },
    { id: 5, url: Image4, types: [], keywords: [] },
    { id: 6, url: Image9, types: [], keywords: [] },
    { id: 7, url: House1, types: [], keywords: [] },
    { id: 8, url: Image2, types: [], keywords: [] },
    { id: 9, url: Image3, types: [], keywords: [] },
    { id: 10, url: Image5, types: [], keywords: [] },
    { id: 11, url: Image4, types: [], keywords: [] },
    { id: 12, url: Image9, types: [], keywords: [] },
    { id: 13, url: Image5, types: [], keywords: [] },
    { id: 14, url: Image4, types: [], keywords: [] },
    { id: 15, url: Image9, types: [], keywords: [] },
    { id: 16, url: House1, types: [], keywords: [] },
    { id: 17, url: Image2, types: [], keywords: [] },
    { id: 18, url: Image3, types: [], keywords: [] },
    { id: 19, url: Image5, types: [], keywords: [] },
    { id: 20, url: Image4, types: [], keywords: [] },
    { id: 21, url: Image9, types: [], keywords: [] },
  ]);

  const onTypeChange = (e) => {
    setSelectedTypes(e);
  }

  const filterByType = (image) => selectedTypes?.length === 0 || image?.types?.some(r => selectedTypes.indexOf(r) >= 0);

  const onKeywordChange = (e) => {
    setSelectedKeywords(e);
  }

  const filterByKeywords = (image) => selectedKeywords?.length === 0 || image?.keywords?.some(r => selectedKeywords.indexOf(r) >= 0);

  const typeOptions = [
    {
      id: 'illustration',
      text: 'Illustration'
    },
    {
      id: 'graphic_design',
      text: 'Graphic Design'
    },
    {
      id: 'facilitation',
      text: 'Facilitation'
    },
    {
      id: 'graphic_recording',
      text: 'Graphic Recording'
    }
  ];
  const keywordOptions = [
    {
      id: 'food',
      text: 'Food'
    },
    {
      id: 'aperol',
      text: 'Aperol'
    },
    {
      id: 'building',
      text: 'Building'
    },
    {
      id: 'packaging',
      text: 'Packaging'
    }
    ,
    {
      id: 'live_graphic_recording',
      text: 'Live Graphic Recording'
    },
    {
      id: 'recipe_illustration',
      text: 'Recipe Illustration'
    },
  ];
  const FilterForm = ({}) => (
    <Form form={form}>
      <Row style={{ marginTop: 20 }}>
        <Text style={{ fontFamily: "IBM Plex Sans, sans-serif", fontSize: 13, fontWeight: 500 }}>Type</Text>
      </Row>
      <Row>
        <Checkbox.Group
          onChange={onTypeChange}
          value={selectedTypes}
        >
          <Col>
            {
              typeOptions?.map((option) => (
                <Row style={{ marginTop: 2 }}>
                  <Checkbox value={option?.id}>{option?.text}</Checkbox>
                </Row>
              ))
            }
          </Col>
        </Checkbox.Group>
      </Row>
      <Row style={{ marginTop: 20 }}>
        <Text style={{ fontFamily: "IBM Plex Sans, sans-serif", fontSize: 13, fontWeight: 500 }}>Keywords</Text>
      </Row>
      <Row>
        <Checkbox.Group
          onChange={onKeywordChange}
          value={selectedKeywords}
        >
          <Col>
            {
              keywordOptions?.map((option) => (
                <Row style={{ marginTop: 2 }}>
                  <Checkbox value={option?.id}>{option?.text}</Checkbox>
                </Row>
              ))
            }
          </Col>
        </Checkbox.Group>
      </Row>
    </Form>
  );
  return (
    <>
      {
        imageModalActive && (
          <div className='modal-container'>
            <div style={{ height: '100vh', width: '100vw', backgroundColor: 'none', position: 'fixed', top: 0, left: 0, zIndex: 50 }} onClick={() => setImageModalActive(false)} />
            <div
              className='modal-card'
              style={{ backgroundImage: `url(${images?.filter((image) => image.id === activeImage)?.[0]?.url}` }}
            />
          </div>
        )
      }
      <div style={imageModalActive ? { overflow: 'hidden', height: '100vh' } : null}>
        <MenuBar />
        <Row>
          <Col xs={{ span: 0, offset: 0 }} lg={{ span: 3, offset: 3 }} style={{ left: 0, top: 180, position: 'fixed' }}>
            <Row>
              <Col>
                <Row>
                  <Text style={{ fontFamily: "IBM Plex Sans, sans-serif", fontSize: 13, color: '#888888', fontWeight: 500 }}>Refine search</Text>
                </Row>
                <FilterForm/>
              </Col>
            </Row>
          </Col>
          <Col xs={{ span: 18, offset: 3 }} sm={{ span: 20, offset: 2 }} lg={{ span: 12, offset: 6 }}>
            {!screens?.lg && <Button onClick={showModal} icon={<FilterOutlined />} type="text" style={{ transform: 'translateX(-15px)' }}>Filter</Button>}
            <div className={screens?.md ? 'portfolio-image-mosaic' : `portfolio-image-mosaic-xs ${activeImage ? 'small-mosaic' : ''}`}>
              {images?.filter(filterByType)?.filter(filterByKeywords)?.map((image) => (
                <div style={{ backgroundColor: '#fff' }}>
                  <div
                    onClick={() => {setActiveImage(image?.id); setImageModalActive(true);}}
                    key={image?.id}
                    className='card'
                    style={{ backgroundImage: `url(${image?.url}` }}
                    loading="lazy"
                  />
                </div>
              ))}
              {images?.filter(filterByType)?.filter(filterByKeywords)?.length === 0 && <div style={{ marginTop: 40 }}>No images found</div>}
            </div>
          </Col>

        </Row>
        <Modal
          title="Refine search"
          open={isModalOpen}
          onOk={handleOk}
          okText="Confirm"
          closable={false}
          centered
          footer={[
            <Button key="submit" onClick={handleOk}>
              Close
            </Button>,
          ]}
        >
          <FilterForm />
        </Modal>
      </div>
    </>
  );
}

export default Portfolio;
